<template>
  <div
    class="modal fade z-index-2555 modal-fullscreen-md-down modal-active"
    id="avatarModal"
    tabindex="-1"
    aria-labelledby="avatarEditorLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header py-0">
          <h5 class="modal-title">
            <span className="name"> Avatar Edit</span>
            <ul className="menu list-inline d-inline-block fs-5 mb-0">
              <li
                className="menu-item border input-wrapper bg-secondary text-white list-inline-item cur-pointer"
              >
                Choose Image
                <input type="file" accept="image/*" id="input-image-file" />
              </li>
              <!--                <li className="menu-item border" id="btn-download">Download</li>-->
              <li className="menu-item border bg-primary text-white list-inline-item" id="btn-save">
                Save
              </li>
            </ul>
          </h5>
          <button
            type="button"
            class="btn-close"
            id="btnCloseModal"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!--          image toolbar-->
          <div className="tui-image-editor-controls">
            <div className="header">
              <!--              <img className="logo" src="img/TOAST UI Component.png" />-->
            </div>
            <ul className="menu top-menu">
              <li className="menu-item disabled" id="btn-undo" title="Undo">
                <i class="fas fa-undo"></i>
              </li>
              <li className="menu-item disabled" id="btn-redo" title="Redo">
                <i class="fas fa-redo"></i>
              </li>
              <li className="menu-item" id="btn-clear-objects" title="Clear Objects">
                <i class="fas fa-eraser"></i>
              </li>
              <li className="menu-item" id="btn-remove-active-object" title="Remove Active Object">
                <i class="fas fa-trash-alt"></i>
              </li>
              <li className="menu-item" id="btn-crop" title="Crop"><i class="fas fa-crop"></i></li>
              <li className="menu-item" id="btn-flip" title="Flip">
                <i class="fas fa-compress"></i>
              </li>
              <li className="menu-item" id="btn-rotation" title="Rotation">
                <i class="fas fa-sync"></i>
              </li>
              <li className="menu-item" id="btn-draw-line" title="DrawLine">
                <i class="fas fa-pencil-ruler"></i>
              </li>
              <li className="menu-item" id="btn-draw-shape" title="Shape">
                <i class="fas fa-shapes"></i>
              </li>
              <li className="menu-item" id="btn-add-icon" title="Icon">
                <i class="fas fa-star"></i>
              </li>
              <li className="menu-item" id="btn-text" title="Text">
                <i class="fas fa-remove-format"></i>
              </li>
              <li className="menu-item" id="btn-mask-filter" title="Mask">
                <i class="fas fa-theater-masks"></i>
              </li>
              <li className="menu-item" id="btn-image-filter" title="Filter">
                <i class="fas fa-filter"></i>
              </li>
            </ul>
            <div className="sub-menu-container" id="crop-sub-menu">
              <ul className="menu">
                <li className="menu-item" id="btn-apply-crop">Apply</li>
                <li className="menu-item" id="btn-cancel-crop">Cancel</li>
              </ul>
            </div>
            <div className="sub-menu-container" id="flip-sub-menu">
              <ul className="menu">
                <li className="menu-item" id="btn-flip-x">FlipX</li>
                <li className="menu-item" id="btn-flip-y">FlipY</li>
                <li className="menu-item" id="btn-reset-flip">Reset</li>
                <li className="menu-item close">Close</li>
              </ul>
            </div>
            <div className="sub-menu-container" id="rotation-sub-menu">
              <ul className="menu">
                <li className="menu-item" id="btn-rotate-clockwise">Clockwise(30)</li>
                <li className="menu-item" id="btn-rotate-counter-clockwise">
                  Counter-Clockwise(-30)
                </li>
                <li className="menu-item no-pointer">
                  <label>
                    Range input
                    <input id="input-rotation-range" type="range" min="-360" value="0" max="360" />
                  </label>
                </li>
                <li className="menu-item close">Close</li>
              </ul>
            </div>
            <div className="sub-menu-container menu" id="draw-line-sub-menu">
              <ul className="menu">
                <li className="menu-item">
                  <label>
                    <input
                      type="radio"
                      name="select-line-type"
                      value="freeDrawing"
                      checked="checked"
                    />
                    Free drawing
                  </label>
                  <label>
                    <input type="radio" name="select-line-type" value="lineDrawing" />
                    Straight line
                  </label>
                </li>
                <li className="menu-item">
                  <div id="tui-brush-color-picker">Brush color</div>
                </li>
                <li className="menu-item">
                  <label className="menu-item no-pointer">
                    Brush width
                    <input id="input-brush-width-range" type="range" min="5" max="30" value="12" />
                  </label>
                </li>
                <li className="menu-item close">Close</li>
              </ul>
            </div>
            <div className="sub-menu-container" id="draw-shape-sub-menu">
              <ul className="menu">
                <li className="menu-item">
                  <label>
                    <input type="radio" name="select-shape-type" value="rect" checked="checked" />
                    rect
                  </label>
                  <label>
                    <input type="radio" name="select-shape-type" value="circle" />
                    circle
                  </label>
                  <label>
                    <input type="radio" name="select-shape-type" value="triangle" />
                    triangle
                  </label>
                </li>
                <li className="menu-item">
                  <select name="select-color-type">
                    <option value="fill">Fill</option>
                    <option value="stroke">Stroke</option>
                  </select>
                  <label>
                    <input
                      type="radio"
                      name="input-check-fill"
                      id="input-check-transparent"
                      value="transparent"
                    />
                    transparent
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="input-check-fill"
                      id="input-check-filter"
                      value="filter"
                    />
                    filter
                  </label>
                  <div id="tui-shape-color-picker"></div>
                </li>
                <li className="menu-item">
                  <label className="menu-item no-pointer">
                    Stroke width
                    <input
                      id="input-stroke-width-range"
                      type="range"
                      min="0"
                      max="300"
                      value="12"
                    />
                  </label>
                </li>
                <li className="menu-item close">Close</li>
              </ul>
            </div>
            <div className="sub-menu-container" id="icon-sub-menu">
              <ul className="menu">
                <li className="menu-item">
                  <div id="tui-icon-color-picker">Icon color</div>
                </li>
                <li className="menu-item border" id="btn-register-icon">Register custom icon</li>
                <li className="menu-item icon-text" data-icon-type="arrow">➡</li>
                <li className="menu-item icon-text" data-icon-type="cancel">✖</li>
                <li className="menu-item close">Close</li>
              </ul>
            </div>
            <div className="sub-menu-container" id="text-sub-menu">
              <ul className="menu">
                <li className="menu-item">
                  <div>
                    <button className="btn-text-style" data-style-type="b">Bold</button>
                    <button className="btn-text-style" data-style-type="i">Italic</button>
                    <button className="btn-text-style" data-style-type="u">Underline</button>
                  </div>
                  <div>
                    <button className="btn-text-style" data-style-type="l">Left</button>
                    <button className="btn-text-style" data-style-type="c">Center</button>
                    <button className="btn-text-style" data-style-type="r">Right</button>
                  </div>
                </li>
                <li className="menu-item">
                  <label className="no-pointer">
                    <input id="input-font-size-range" type="range" min="10" max="100" value="10" />
                  </label>
                </li>
                <li className="menu-item">
                  <div id="tui-text-color-picker">Text color</div>
                </li>
                <li className="menu-item close">Close</li>
              </ul>
            </div>
            <div className="sub-menu-container" id="filter-sub-menu">
              <ul className="menu">
                <li className="menu-item border input-wrapper">
                  Load Mask Image
                  <input type="file" accept="image/*" id="input-mask-image-file" />
                </li>
                <li className="menu-item" id="btn-apply-mask">Apply mask filter</li>
                <li className="menu-item close">Close</li>
              </ul>
            </div>
            <div className="sub-menu-container" id="image-filter-sub-menu">
              <ul className="menu">
                <li className="menu-item align-left-top">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <label
                            ><input type="checkbox" id="input-check-grayscale" />Grayscale</label
                          >
                        </td>
                        <td>
                          <label><input type="checkbox" id="input-check-invert" />Invert</label>
                        </td>
                        <td>
                          <label><input type="checkbox" id="input-check-sepia" />Sepia</label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label><input type="checkbox" id="input-check-sepia2" />Sepia2</label>
                        </td>
                        <td>
                          <label><input type="checkbox" id="input-check-blur" />Blur</label>
                        </td>
                        <td>
                          <label><input type="checkbox" id="input-check-sharpen" />Sharpen</label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label><input type="checkbox" id="input-check-emboss" />Emboss</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li className="menu-item align-left-top">
                  <p>
                    <label>
                      <input type="checkbox" id="input-check-remove-white" />
                      RemoveWhite
                    </label>
                    <br />
                    <label>
                      Distance
                      <input
                        className="range-narrow"
                        id="input-range-remove-white-distance"
                        type="range"
                        min="0"
                        value="10"
                        max="255"
                      />
                    </label>
                  </p>
                </li>
                <li className="menu-item align-left-top">
                  <p>
                    <label><input type="checkbox" id="input-check-brightness" />Brightness</label
                    ><br />
                    <label>
                      Value
                      <input
                        className="range-narrow"
                        id="input-range-brightness-value"
                        type="range"
                        min="-255"
                        value="100"
                        max="255"
                      />
                    </label>
                  </p>
                </li>
                <li className="menu-item align-left-top">
                  <p>
                    <label><input type="checkbox" id="input-check-noise" />Noise</label><br />
                    <label>
                      Value
                      <input
                        className="range-narrow"
                        id="input-range-noise-value"
                        type="range"
                        min="0"
                        value="100"
                        max="1000"
                      />
                    </label>
                  </p>
                </li>
                <li className="menu-item align-left-top">
                  <p>
                    <label>
                      <input type="checkbox" id="input-check-color-filter" />
                      ColorFilter
                    </label>
                    <br />
                    <label>
                      Threshold
                      <input
                        className="range-narrow"
                        id="input-range-color-filter-value"
                        type="range"
                        min="0"
                        value="45"
                        max="255"
                      />
                    </label>
                  </p>
                </li>
                <li className="menu-item align-left-top">
                  <p>
                    <label><input type="checkbox" id="input-check-pixelate" />Pixelate</label><br />
                    <label>
                      Value
                      <input
                        className="range-narrow"
                        id="input-range-pixelate-value"
                        type="range"
                        min="2"
                        value="4"
                        max="20"
                      />
                    </label>
                  </p>
                </li>
                <li className="menu-item align-left-top">
                  <p>
                    <label><input type="checkbox" id="input-check-tint" />Tint</label><br />
                  </p>
                  <div id="tui-tint-color-picker"></div>
                  <label>
                    Opacity
                    <input
                      className="range-narrow"
                      id="input-range-tint-opacity-value"
                      type="range"
                      min="0"
                      value="1"
                      max="1"
                      step="0.1"
                    />
                  </label>
                </li>
                <li className="menu-item align-left-top">
                  <p>
                    <label><input type="checkbox" id="input-check-multiply" />Multiply</label>
                  </p>
                  <div id="tui-multiply-color-picker"></div>
                </li>
                <li className="menu-item align-left-top">
                  <p>
                    <label><input type="checkbox" id="input-check-blend" />Blend</label>
                  </p>
                  <div id="tui-blend-color-picker"></div>
                  <select name="select-blend-type">
                    <option value="add" selected>Add</option>
                    <option value="diff">Diff</option>
                    <option value="diff">Subtract</option>
                    <option value="multiply">Multiply</option>
                    <option value="screen">Screen</option>
                    <option value="lighten">Lighten</option>
                    <option value="darken">Darken</option>
                  </select>
                </li>
                <li className="menu-item close">Close</li>
              </ul>
            </div>
          </div>
          <!--         end of image toolbar-->
          <div className="tui-image-editor" id="avatar-editor"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jQuery from 'jquery';
//eslint-disable-next-line
const $ = jQuery.noConflict();
//eslint-disable-next-line
import ImageEditor from 'tui-image-editor';
//eslint-disable-next-line
const FileSaver = require('file-saver');
//eslint-disable-next-line
const fabric = require('fabric');
//eslint-disable-next-line
const colorPicker = require('tui-color-picker');
//eslint-disable-next-line
const forEach = require('tui-code-snippet/collection/forEach');
export default {
  name: 'AvatarEditor',
  props: { image: String },
  emits: ['updateAvatar'],
  mounted() {
    /**
     * service-basic.js
     * @author NHN. FE Development Team <dl_javascript@nhn.com>
     * @fileoverview
     */
    /* eslint-disable vars-on-top,no-var,strict,prefer-template,prefer-arrow-callback,prefer-destructuring,object-shorthand,require-jsdoc,complexity,prefer-const,no-unused-vars */
    var PIXELATE_FILTER_DEFAULT_VALUE = 20;
    var supportingFileAPI = !!(window.File && window.FileList && window.FileReader);
    var rImageType = /data:(image\/.+);base64,/;
    var shapeOptions = {};
    var shapeType;
    var activeObjectId;

    // Buttons
    var $btns = $('.menu-item');
    var $btnsActivatable = $btns.filter('.activatable');
    var $inputImage = $('#input-image-file');
    var $btnDownload = $('#btn-download');

    var $btnUndo = $('#btn-undo');
    var $btnRedo = $('#btn-redo');
    var $btnClearObjects = $('#btn-clear-objects');
    var $btnRemoveActiveObject = $('#btn-remove-active-object');
    var $btnCrop = $('#btn-crop');
    var $btnFlip = $('#btn-flip');
    var $btnRotation = $('#btn-rotation');
    var $btnDrawLine = $('#btn-draw-line');
    var $btnDrawShape = $('#btn-draw-shape');
    var $btnApplyCrop = $('#btn-apply-crop');
    var $btnCancelCrop = $('#btn-cancel-crop');
    var $btnFlipX = $('#btn-flip-x');
    var $btnFlipY = $('#btn-flip-y');
    var $btnResetFlip = $('#btn-reset-flip');
    var $btnRotateClockwise = $('#btn-rotate-clockwise');
    var $btnRotateCounterClockWise = $('#btn-rotate-counter-clockwise');
    var $btnText = $('#btn-text');
    var $btnTextStyle = $('.btn-text-style');
    var $btnAddIcon = $('#btn-add-icon');
    var $btnRegisterIcon = $('#btn-signup-icon');
    var $btnMaskFilter = $('#btn-mask-filter');
    var $btnImageFilter = $('#btn-image-filter');
    var $btnLoadMaskImage = $('#input-mask-image-file');
    var $btnApplyMask = $('#btn-apply-mask');
    var $btnClose = $('.close');

    // Input etc.
    var $inputRotationRange = $('#input-rotation-range');
    var $inputBrushWidthRange = $('#input-brush-width-range');
    var $inputFontSizeRange = $('#input-font-size-range');
    var $inputStrokeWidthRange = $('#input-stroke-width-range');
    var $inputCheckTransparent = $('#input-check-transparent');
    var $inputCheckFilter = $('#input-check-filter');
    var $inputCheckGrayscale = $('#input-check-grayscale');
    var $inputCheckInvert = $('#input-check-invert');
    var $inputCheckSepia = $('#input-check-sepia');
    var $inputCheckSepia2 = $('#input-check-sepia2');
    var $inputCheckBlur = $('#input-check-blur');
    var $inputCheckSharpen = $('#input-check-sharpen');
    var $inputCheckEmboss = $('#input-check-emboss');
    var $inputCheckRemoveWhite = $('#input-check-remove-white');
    var $inputRangeRemoveWhiteThreshold = $('#input-range-remove-white-threshold');
    var $inputRangeRemoveWhiteDistance = $('#input-range-remove-white-distance');
    var $inputCheckBrightness = $('#input-check-brightness');
    var $inputRangeBrightnessValue = $('#input-range-brightness-value');
    var $inputCheckNoise = $('#input-check-noise');
    var $inputRangeNoiseValue = $('#input-range-noise-value');
    var $inputCheckPixelate = $('#input-check-pixelate');
    var $inputRangePixelateValue = $('#input-range-pixelate-value');
    var $inputCheckTint = $('#input-check-tint');
    var $inputRangeTintOpacityValue = $('#input-range-tint-opacity-value');
    var $inputCheckMultiply = $('#input-check-multiply');
    var $inputCheckBlend = $('#input-check-blend');
    var $inputCheckColorFilter = $('#input-check-color-filter');
    var $inputRangeColorFilterValue = $('#input-range-color-filter-value');

    // Sub menus
    var $displayingSubMenu = $();
    var $cropSubMenu = $('#crop-sub-menu');
    var $flipSubMenu = $('#flip-sub-menu');
    var $rotationSubMenu = $('#rotation-sub-menu');
    var $freeDrawingSubMenu = $('#free-drawing-sub-menu');
    var $drawLineSubMenu = $('#draw-line-sub-menu');
    var $drawShapeSubMenu = $('#draw-shape-sub-menu');
    var $textSubMenu = $('#text-sub-menu');
    var $iconSubMenu = $('#icon-sub-menu');
    var $filterSubMenu = $('#filter-sub-menu');
    var $imageFilterSubMenu = $('#image-filter-sub-menu');

    // Select line type
    var $selectLine = $('[name="select-line-type"]');

    // Select shape type
    var $selectShapeType = $('[name="select-shape-type"]');

    // Select color of shape type
    var $selectColorType = $('[name="select-color-type"]');

    // Select blend type
    var $selectBlendType = $('[name="select-blend-type"]');

    // Image editor
    var imageEditor = new ImageEditor('.tui-image-editor', {
      cssMaxWidth: 700,
      cssMaxHeight: 500,
      selectionStyle: {
        cornerSize: 20,
        rotatingPointOffset: 70,
      },
    });

    // Color picker for free drawing
    var brushColorpicker = colorPicker.create({
      container: $('#tui-brush-color-picker')[0],
      color: '#000000',
    });

    // Color picker for text palette
    var textColorpicker = colorPicker.create({
      container: $('#tui-text-color-picker')[0],
      color: '#000000',
    });

    // Color picker for shape
    var shapeColorpicker = colorPicker.create({
      container: $('#tui-shape-color-picker')[0],
      color: '#000000',
    });

    // Color picker for icon
    var iconColorpicker = colorPicker.create({
      container: $('#tui-icon-color-picker')[0],
      color: '#000000',
    });

    var tintColorpicker = colorPicker.create({
      container: $('#tui-tint-color-picker')[0],
      color: '#000000',
    });

    var multiplyColorpicker = colorPicker.create({
      container: $('#tui-multiply-color-picker')[0],
      color: '#000000',
    });

    var blendColorpicker = colorPicker.create({
      container: $('#tui-blend-color-picker')[0],
      color: '#00FF00',
    });

    // Common global functions
    // HEX to RGBA
    function hexToRGBa(hex, alpha) {
      var r = parseInt(hex.slice(1, 3), 16);
      var g = parseInt(hex.slice(3, 5), 16);
      var b = parseInt(hex.slice(5, 7), 16);
      var a = alpha || 1;

      return 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')';
    }

    function base64ToBlob(data) {
      var mimeString = '';
      var raw, uInt8Array, i, rawLength;

      raw = data.replace(rImageType, function (header, imageType) {
        mimeString = imageType;

        return '';
      });

      raw = atob(raw);
      rawLength = raw.length;
      uInt8Array = new Uint8Array(rawLength); // eslint-disable-line

      for (i = 0; i < rawLength; i += 1) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: mimeString });
    }

    function resizeEditor() {
      var $editor = $('.tui-image-editor');
      var $container = $('.tui-image-editor-canvas-container');
      var height = parseFloat($container.css('max-height'));

      $editor.height(height);
    }

    function getBrushSettings() {
      var brushWidth = parseInt($inputBrushWidthRange.val(), 10);
      var brushColor = brushColorpicker.getColor();

      return {
        width: brushWidth,
        color: hexToRGBa(brushColor, 0.5),
      };
    }

    function activateShapeMode() {
      if (imageEditor.getDrawingMode() !== 'SHAPE') {
        imageEditor.stopDrawingMode();
        imageEditor.startDrawingMode('SHAPE');
      }
    }

    function activateIconMode() {
      imageEditor.stopDrawingMode();
    }

    function activateTextMode() {
      if (imageEditor.getDrawingMode() !== 'TEXT') {
        imageEditor.stopDrawingMode();
        imageEditor.startDrawingMode('TEXT');
      }
    }

    function setTextToolbar(obj) {
      var fontSize = obj.fontSize;
      var fontColor = obj.fill;

      $inputFontSizeRange.val(fontSize);
      textColorpicker.setColor(fontColor);
    }

    function setIconToolbar(obj) {
      var iconColor = obj.fill;

      iconColorpicker.setColor(iconColor);
    }

    function setShapeToolbar(obj) {
      var fillColor, isTransparent, isFilter;
      var colorType = $selectColorType.val();
      var changeValue = colorType === 'stroke' ? obj.stroke : obj.fill.type;
      isTransparent = changeValue === 'transparent';
      isFilter = changeValue === 'filter';

      if (colorType === 'stroke') {
        if (!isTransparent && !isFilter) {
          shapeColorpicker.setColor(changeValue);
        }
      } else if (colorType === 'fill') {
        if (!isTransparent && !isFilter) {
          fillColor = obj.fill.color;
          shapeColorpicker.setColor(fillColor);
        }
      }

      $inputCheckTransparent.prop('checked', isTransparent);
      $inputCheckFilter.prop('checked', isFilter);
      $inputStrokeWidthRange.val(obj.strokeWidth);
    }

    function showSubMenu(type) {
      var $submenu;

      switch (type) {
        case 'shape':
          $submenu = $drawShapeSubMenu;
          break;
        case 'icon':
          $submenu = $iconSubMenu;
          break;
        case 'text':
          $submenu = $textSubMenu;
          break;
        default:
          $submenu = 0;
      }

      $displayingSubMenu.hide();
      $displayingSubMenu = $submenu.show();
    }

    function applyOrRemoveFilter(applying, type, options) {
      if (applying) {
        imageEditor.applyFilter(type, options).then(function (result) {
          console.log(result);
        });
      } else {
        imageEditor.removeFilter(type);
      }
    }

    // Attach image editor custom events
    imageEditor.on({
      objectAdded: function (objectProps) {
        console.info(objectProps);
      },
      undoStackChanged: function (length) {
        if (length) {
          $btnUndo.removeClass('disabled');
        } else {
          $btnUndo.addClass('disabled');
        }
        resizeEditor();
      },
      redoStackChanged: function (length) {
        if (length) {
          $btnRedo.removeClass('disabled');
        } else {
          $btnRedo.addClass('disabled');
        }
        resizeEditor();
      },
      objectScaled: function (obj) {
        if (obj.type === 'text') {
          $inputFontSizeRange.val(obj.fontSize);
        }
      },
      addText: function (pos) {
        imageEditor
          .addText('Double Click', {
            position: pos.originPosition,
          })
          .then(function (objectProps) {
            console.log(objectProps);
          });
      },
      objectActivated: function (obj) {
        activeObjectId = obj.id;
        if (obj.type === 'rect' || obj.type === 'circle' || obj.type === 'triangle') {
          showSubMenu('shape');
          setShapeToolbar(obj);
          activateShapeMode();
        } else if (obj.type === 'icon') {
          showSubMenu('icon');
          setIconToolbar(obj);
          activateIconMode();
        } else if (obj.type === 'text') {
          showSubMenu('text');
          setTextToolbar(obj);
          activateTextMode();
        }
      },
      mousedown: function (event, originPointer) {
        if ($imageFilterSubMenu.is(':visible') && imageEditor.hasFilter('colorFilter')) {
          imageEditor.applyFilter('colorFilter', {
            x: parseInt(originPointer.x, 10),
            y: parseInt(originPointer.y, 10),
          });
        }
      },
    });

    // Attach button click event listeners
    $btns.on('click', function () {
      $btnsActivatable.removeClass('active');
    });

    $btnsActivatable.on('click', function () {
      $(this).addClass('active');
    });

    $btnUndo.on('click', function () {
      $displayingSubMenu.hide();

      if (!$(this).hasClass('disabled')) {
        imageEditor.discardSelection();
        imageEditor.undo();
      }
    });

    $btnRedo.on('click', function () {
      $displayingSubMenu.hide();

      if (!$(this).hasClass('disabled')) {
        imageEditor.discardSelection();
        imageEditor.redo();
      }
    });

    $btnClearObjects.on('click', function () {
      $displayingSubMenu.hide();
      imageEditor.clearObjects();
    });

    $btnRemoveActiveObject.on('click', function () {
      $displayingSubMenu.hide();
      imageEditor.removeObject(activeObjectId);
    });

    $btnCrop.on('click', function () {
      imageEditor.startDrawingMode('CROPPER');
      $displayingSubMenu.hide();
      $displayingSubMenu = $cropSubMenu.show();
    });

    $btnFlip.on('click', function () {
      imageEditor.stopDrawingMode();
      $displayingSubMenu.hide();
      $displayingSubMenu = $flipSubMenu.show();
    });

    $btnRotation.on('click', function () {
      imageEditor.stopDrawingMode();
      $displayingSubMenu.hide();
      $displayingSubMenu = $rotationSubMenu.show();
    });

    $btnClose.on('click', function () {
      imageEditor.stopDrawingMode();
      $displayingSubMenu.hide();
    });

    $btnApplyCrop.on('click', function () {
      imageEditor.crop(imageEditor.getCropzoneRect()).then(function () {
        imageEditor.stopDrawingMode();
        resizeEditor();
      });
    });

    $btnCancelCrop.on('click', function () {
      imageEditor.stopDrawingMode();
    });

    $btnFlipX.on('click', function () {
      imageEditor.flipX().then(function (status) {
        console.log('flipX: ', status.flipX);
        console.log('flipY: ', status.flipY);
        console.log('angle: ', status.angle);
      });
    });

    $btnFlipY.on('click', function () {
      imageEditor.flipY().then(function (status) {
        console.log('flipX: ', status.flipX);
        console.log('flipY: ', status.flipY);
        console.log('angle: ', status.angle);
      });
    });

    $btnResetFlip.on('click', function () {
      imageEditor.resetFlip().then(function (status) {
        console.log('flipX: ', status.flipX);
        console.log('flipY: ', status.flipY);
        console.log('angle: ', status.angle);
      });
    });

    $btnRotateClockwise.on('click', function () {
      imageEditor.rotate(30);
    });

    $btnRotateCounterClockWise.on('click', function () {
      imageEditor.rotate(-30);
    });

    $inputRotationRange.on('mousedown', function () {
      var changeAngle = function () {
        imageEditor.setAngle(parseInt($inputRotationRange.val(), 10))['catch'](function () {});
      };
      $(document).on('mousemove', changeAngle);
      $(document).on('mouseup', function stopChangingAngle() {
        $(document).off('mousemove', changeAngle);
        $(document).off('mouseup', stopChangingAngle);
      });
    });

    $inputRotationRange.on('change', function () {
      imageEditor.setAngle(parseInt($inputRotationRange.val(), 10))['catch'](function () {});
    });

    $inputBrushWidthRange.on('change', function () {
      imageEditor.setBrush({ width: parseInt(this.value, 10) });
    });

    $inputImage.on('change', function (event) {
      var file;

      if (!supportingFileAPI) {
        alert('This browser does not support file-api');
      }

      file = event.target.files[0];
      imageEditor.loadImageFromFile(file).then(function (result) {
        console.log(result);
        imageEditor.clearUndoStack();
      });
    });

    $btnDownload.on('click', function () {
      var imageName = imageEditor.getImageName();
      var dataURL = imageEditor.toDataURL();
      var blob, type, w;

      if (supportingFileAPI) {
        blob = base64ToBlob(dataURL);
        type = blob.type.split('/')[1];
        if (imageName.split('.').pop() !== type) {
          imageName += '.' + type;
        }

        // Library: FileSaver - saveAs
        saveAs(blob, imageName); // eslint-disable-line
      } else {
        alert('This browser needs a file-server');
        w = window.open();
        w.document.body.innerHTML = '<img src="' + dataURL + '">';
      }
    });

    // control draw line mode
    $btnDrawLine.on('click', function () {
      imageEditor.stopDrawingMode();
      $displayingSubMenu.hide();
      $displayingSubMenu = $drawLineSubMenu.show();
      $selectLine.eq(0).change();
    });

    $selectLine.on('change', function () {
      var mode = $(this).val();
      var settings = getBrushSettings();

      imageEditor.stopDrawingMode();
      if (mode === 'freeDrawing') {
        imageEditor.startDrawingMode('FREE_DRAWING', settings);
      } else {
        imageEditor.startDrawingMode('LINE_DRAWING', settings);
      }
    });

    brushColorpicker.on('selectColor', function (event) {
      imageEditor.setBrush({
        color: hexToRGBa(event.color, 0.5),
      });
    });

    // control draw shape mode
    $btnDrawShape.on('click', function () {
      showSubMenu('shape');

      // step 1. get options to draw shape from toolbar
      shapeType = $('[name="select-shape-type"]:checked').val();

      shapeOptions.stroke = '#000000';
      shapeOptions.fill = '#ffffff';

      shapeOptions.strokeWidth = Number($inputStrokeWidthRange.val());

      // step 2. set options to draw shape
      imageEditor.setDrawingShape(shapeType, shapeOptions);

      // step 3. start drawing shape mode
      activateShapeMode();
    });

    $selectShapeType.on('change', function () {
      shapeType = $(this).val();

      imageEditor.setDrawingShape(shapeType);
    });
    $selectColorType.on('change', function () {
      var colorType = $(this).val();
      if (colorType === 'stroke') {
        $inputCheckFilter.prop('disabled', true);
        $inputCheckFilter.prop('checked', false);
      } else {
        $inputCheckTransparent.prop('disabled', false);
        $inputCheckFilter.prop('disabled', false);
      }
    });

    $inputCheckTransparent.on('change', onChangeShapeFill);
    $inputCheckFilter.on('change', onChangeShapeFill);
    shapeColorpicker.on('selectColor', function (event) {
      $inputCheckTransparent.prop('checked', false);
      $inputCheckFilter.prop('checked', false);
      onChangeShapeFill(event);
    });

    function onChangeShapeFill(event) {
      var colorType = $selectColorType.val();
      var isTransparent = $inputCheckTransparent.prop('checked');
      var isFilter = $inputCheckFilter.prop('checked');
      var shapeOption;

      if (event.color) {
        shapeOption = event.color;
      } else if (isTransparent) {
        shapeOption = 'transparent';
      } else if (isFilter) {
        shapeOption = {
          type: 'filter',
          filter: [{ pixelate: PIXELATE_FILTER_DEFAULT_VALUE }],
        };
      }

      if (colorType === 'stroke') {
        imageEditor.changeShape(activeObjectId, {
          stroke: shapeOption,
        });
      } else if (colorType === 'fill') {
        imageEditor.changeShape(activeObjectId, {
          fill: shapeOption,
        });
      }

      imageEditor.setDrawingShape(shapeType, shapeOptions);
    }

    $inputStrokeWidthRange.on('change', function () {
      var strokeWidth = Number($(this).val());

      imageEditor.changeShape(activeObjectId, {
        strokeWidth: strokeWidth,
      });

      imageEditor.setDrawingShape(shapeType, shapeOptions);
    });

    // control text mode
    $btnText.on('click', function () {
      showSubMenu('text');
      activateTextMode();
    });

    $inputFontSizeRange.on('change', function () {
      imageEditor.changeTextStyle(activeObjectId, {
        fontSize: parseInt(this.value, 10),
      });
    });

    $btnTextStyle.on('click', function (e) {
      // eslint-disable-line
      var styleType = $(this).attr('data-style-type');
      var styleObj;

      e.stopPropagation();

      switch (styleType) {
        case 'b':
          styleObj = { fontWeight: 'bold' };
          break;
        case 'i':
          styleObj = { fontStyle: 'italic' };
          break;
        case 'u':
          styleObj = { underline: true };
          break;
        case 'l':
          styleObj = { textAlign: 'left' };
          break;
        case 'c':
          styleObj = { textAlign: 'center' };
          break;
        case 'r':
          styleObj = { textAlign: 'right' };
          break;
        default:
          styleObj = {};
      }

      imageEditor.changeTextStyle(activeObjectId, styleObj);
    });

    textColorpicker.on('selectColor', function (event) {
      imageEditor.changeTextStyle(activeObjectId, {
        fill: event.color,
      });
    });

    // control icon
    $btnAddIcon.on('click', function () {
      showSubMenu('icon');
      activateIconMode();
    });

    function onClickIconSubMenu(event) {
      var element = event.target || event.srcElement;
      var iconType = $(element).attr('data-icon-type');

      imageEditor.once('mousedown', function (e, originPointer) {
        imageEditor
          .addIcon(iconType, {
            left: originPointer.x,
            top: originPointer.y,
          })
          .then(function (objectProps) {
            // console.log(objectProps);
          });
      });
    }

    $btnRegisterIcon.on('click', function () {
      $iconSubMenu
        .find('.menu-item')
        .eq(3)
        .after(
          '<li id="customArrow" class="menu-item icon-text" data-icon-type="customArrow">↑</li>'
        );

      imageEditor.registerIcons({
        customArrow: 'M 60 0 L 120 60 H 90 L 75 45 V 180 H 45 V 45 L 30 60 H 0 Z',
      });

      $btnRegisterIcon.off('click');

      $iconSubMenu.on('click', '#customArrow', onClickIconSubMenu);
    });

    $iconSubMenu.on('click', '.icon-text', onClickIconSubMenu);

    iconColorpicker.on('selectColor', function (event) {
      imageEditor.changeIconColor(activeObjectId, event.color);
    });

    // control mask filter
    $btnMaskFilter.on('click', function () {
      imageEditor.stopDrawingMode();
      $displayingSubMenu.hide();

      $displayingSubMenu = $filterSubMenu.show();
    });

    $btnImageFilter.on('click', function () {
      var filters = {
        grayscale: $inputCheckGrayscale,
        invert: $inputCheckInvert,
        sepia: $inputCheckSepia,
        sepia2: $inputCheckSepia2,
        blur: $inputCheckBlur,
        shapren: $inputCheckSharpen,
        emboss: $inputCheckEmboss,
        removeWhite: $inputCheckRemoveWhite,
        brightness: $inputCheckBrightness,
        noise: $inputCheckNoise,
        pixelate: $inputCheckPixelate,
        tint: $inputCheckTint,
        multiply: $inputCheckMultiply,
        blend: $inputCheckBlend,
        colorFilter: $inputCheckColorFilter,
      };

      forEach(filters, function ($value, key) {
        $value.prop('checked', imageEditor.hasFilter(key));
      });
      // filters.forEach((element, index) => {
      //   element.prop("checked", imageEditor.hasFilter(index));
      // });
      $displayingSubMenu.hide();

      $displayingSubMenu = $imageFilterSubMenu.show();
    });

    $btnLoadMaskImage.on('change', function () {
      var file;
      var imgUrl;

      if (!supportingFileAPI) {
        alert('This browser does not support file-api');
      }

      file = event.target.files[0];

      if (file) {
        imgUrl = URL.createObjectURL(file);

        imageEditor.loadImageFromURL(imageEditor.toDataURL(), 'FilterImage').then(function () {
          imageEditor.addImageObject(imgUrl).then(function (objectProps) {
            URL.revokeObjectURL(file);
            console.log(objectProps);
          });
        });
      }
    });

    $btnApplyMask.on('click', function () {
      imageEditor
        .applyFilter('mask', {
          maskObjId: activeObjectId,
        })
        .then(function (result) {
          console.log(result);
        });
    });

    $inputCheckGrayscale.on('change', function () {
      applyOrRemoveFilter(this.checked, 'Grayscale', null);
    });

    $inputCheckInvert.on('change', function () {
      applyOrRemoveFilter(this.checked, 'Invert', null);
    });

    $inputCheckSepia.on('change', function () {
      applyOrRemoveFilter(this.checked, 'Sepia', null);
    });

    $inputCheckSepia2.on('change', function () {
      applyOrRemoveFilter(this.checked, 'vintage', null);
    });

    $inputCheckBlur.on('change', function () {
      applyOrRemoveFilter(this.checked, 'Blur', { blur: 0.1 });
    });

    $inputCheckSharpen.on('change', function () {
      applyOrRemoveFilter(this.checked, 'Sharpen', null);
    });

    $inputCheckEmboss.on('change', function () {
      applyOrRemoveFilter(this.checked, 'Emboss', null);
    });

    $inputCheckRemoveWhite.on('change', function () {
      applyOrRemoveFilter(this.checked, 'removeColor', {
        color: '#FFFFFF',
        useAlpha: false,
        distance: parseInt($inputRangeRemoveWhiteDistance.val(), 10) / 255,
      });
    });

    $inputRangeRemoveWhiteDistance.on('change', function () {
      applyOrRemoveFilter($inputCheckRemoveWhite.is(':checked'), 'removeColor', {
        distance: parseInt(this.value, 10) / 255,
      });
    });

    $inputCheckBrightness.on('change', function () {
      applyOrRemoveFilter(this.checked, 'brightness', {
        brightness: parseInt($inputRangeBrightnessValue.val(), 10) / 255,
      });
    });

    $inputRangeBrightnessValue.on('change', function () {
      applyOrRemoveFilter($inputCheckBrightness.is(':checked'), 'brightness', {
        brightness: parseInt(this.value, 10) / 255,
      });
    });

    $inputCheckNoise.on('change', function () {
      applyOrRemoveFilter(this.checked, 'noise', {
        noise: parseInt($inputRangeNoiseValue.val(), 10),
      });
    });

    $inputRangeNoiseValue.on('change', function () {
      applyOrRemoveFilter($inputCheckNoise.is(':checked'), 'noise', {
        noise: parseInt(this.value, 10),
      });
    });

    $inputCheckPixelate.on('change', function () {
      applyOrRemoveFilter(this.checked, 'pixelate', {
        blocksize: parseInt($inputRangePixelateValue.val(), 10),
      });
    });

    $inputRangePixelateValue.on('change', function () {
      applyOrRemoveFilter($inputCheckPixelate.is(':checked'), 'pixelate', {
        blocksize: parseInt(this.value, 10),
      });
    });

    $inputCheckTint.on('change', function () {
      applyOrRemoveFilter(this.checked, 'blendColor', {
        mode: 'tint',
        color: tintColorpicker.getColor(),
        alpha: parseFloat($inputRangeTintOpacityValue.val()),
      });
    });

    tintColorpicker.on('selectColor', function (e) {
      applyOrRemoveFilter($inputCheckTint.is(':checked'), 'blendColor', {
        color: e.color,
      });
    });

    $inputRangeTintOpacityValue.on('change', function () {
      applyOrRemoveFilter($inputCheckTint.is(':checked'), 'blendColor', {
        alpha: parseFloat($inputRangeTintOpacityValue.val()),
      });
    });

    $inputCheckMultiply.on('change', function () {
      applyOrRemoveFilter(this.checked, 'blendColor', {
        color: multiplyColorpicker.getColor(),
      });
    });

    multiplyColorpicker.on('selectColor', function (e) {
      applyOrRemoveFilter($inputCheckMultiply.is(':checked'), 'blendColor', {
        color: e.color,
      });
    });

    $inputCheckBlend.on('change', function () {
      applyOrRemoveFilter(this.checked, 'blendColor', {
        mode: $selectBlendType.val(),
        color: blendColorpicker.getColor(),
      });
    });

    blendColorpicker.on('selectColor', function (e) {
      applyOrRemoveFilter($inputCheckBlend.is(':checked'), 'blendColor', {
        color: e.color,
      });
    });

    $selectBlendType.on('change', function () {
      applyOrRemoveFilter($inputCheckBlend.is(':checked'), 'blendColor', {
        mode: this.value,
      });
    });

    $inputCheckColorFilter.on('change', function () {
      applyOrRemoveFilter(this.checked, 'removeColor', {
        color: '#FFFFFF',
        distance: $inputRangeColorFilterValue.val() / 255,
      });
    });

    $inputRangeColorFilterValue.on('change', function () {
      applyOrRemoveFilter($inputCheckColorFilter.is(':checked'), 'removeColor', {
        distance: this.value / 255,
      });
    });

    // Etc..
    // console.log('image ', this.$props.image);
    // Load sample image
    imageEditor.loadImageFromURL(this.$props.image, 'avatar').then(function (sizeValue) {
      // console.log(sizeValue);
      imageEditor.clearUndoStack();
    });

    // IE9 Unselectable
    $('.menu').on('selectstart', function () {
      return false;
    });
    var unique_id = this.$route.params.unique_id;
    let token = this.$store.state.token;
    let image = this.$props.image;
    var self = this;
    // this.$emit('updateAvatar', 'test');
    $('#btn-save').on('click', function () {
      var imageStringBase64 = imageEditor.toDataURL();
      // console.log('test btn save avatar click', imageEditor.toDataURL());
      let apiUrl = process.env.VUE_APP_API_URL;
      // self.$emit('updateAvatar', 'test');
      $.ajax({
        url: apiUrl + 'user/update-profile-avatar',
        type: 'POST',
        headers: { Authorization: 'Bearer ' + token },
        data: {
          avatar: imageStringBase64,
          unique_id: unique_id,
        },
        error: function (err) {
          switch (err.status) {
            case '400':
              // bad request
              break;
            case '401':
              // unauthorized
              break;
            case '403':
              // forbidden
              break;
            default:
              //Something bad happened
              break;
          }
        },
        success: function (data) {
          console.log('change image Success!', data);
          self.$emit('updateAvatar', data.newAvatarPath);
          $('#btnCloseModal').click();
        },
      });
    });
  },
};
</script>

<style scoped>
@import '../../../../../node_modules/tui-color-picker/dist/tui-color-picker.css';
@import '../../../../../node_modules/tui-image-editor/dist/tui-image-editor.css';
@import '../../../../assets/css/service-basic.css';
#avatar-editor {
  min-height: 500px !important;
}
</style>
